<template>
  <div>
    <v-container fluid class="px-15">
      <v-row justify="start" align="start" class="ma-0">
        <v-col cols="12" class="pa-0">
          <div class="headline-medium text-primary">¡Hola, {{ userName }}!</div>
        </v-col>
        <v-col cols="12" class="pa-0">
          <div class="body-large text-secondary">
            Paga tus facturas y añade fondos cuando quieras.
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="px-15 container-width">
      <v-row justify="space-between" align="start" class="mx-auto">
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card class="card-main" elevation="4">
            <div class="card-content">
              <div class="card-title title-medium">Tipo de cambio actual</div>
              <div class="card-mxn h5">
                {{ formatMoney(actualCurrency) }} MXN
              </div>
              <div class="card-info">
                Tipo de cambio se actualizará en: {{ formattedTime }}
              </div>
              <v-btn
                small
                color="#F0BE43"
                class="btn-custom mt-2"
                elevation="0"
                outlined
                @click="openModalAddFounds"
              >
                <v-icon left dark color="#F0BE43"
                  >mdi-arrow-top-right-thin</v-icon
                >
                <span class="buttonAddFounds">Enviar pago</span>
              </v-btn>
            </div>
            <v-sheet
              class="circle-sheet"
              color="#F0BE43"
              style="border-radius: 155px"
            >
              <div class="circle-content"></div>
            </v-sheet>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card class="card-main" elevation="0" outlined>
            <v-card-text class="pt-6">
              <v-row align="center">
                <v-col cols="9" class="pl-5">
                  <div class="usd-balance">USD Balance</div>
                  <div class="content-balance">
                    ${{ formatMoney(balanceUsd) }} USD
                  </div>
                </v-col>
                <v-col cols="3" class="text-right pr-5">
                  <v-img
                    src="../../../assets/images/Flags_usa.png"
                    alt="usa-flag"
                    width="50"
                    height="30"
                  />
                </v-col>
              </v-row>
              <div class="pl-3 pt-2 blocked-amount">
                Monto bloqueado forwards:
                <span class="blocked-amount-2">$0.00</span>
              </div>
              <v-btn
                small
                color="white"
                class="btn-custom mt-2"
                elevation="0"
                @click="openModalAddFounds"
              >
                <v-icon left dark color="#F0BE43"
                  >mdi-plus-circle-outline</v-icon
                >
                <span class="buttonAddFounds">Añadir fondos</span>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card class="card-main" elevation="0" outlined>
            <v-card-text class="pt-6">
              <v-row align="center">
                <v-col cols="9" class="pl-5">
                  <div class="usd-balance">MXN Balance</div>
                  <div class="content-balance pt-3">
                    ${{ formatMoney(balanceMxn) }} MXN
                  </div>
                </v-col>
                <v-col cols="3" class="text-right pr-5">
                  <v-img
                    src="../../../assets/images/Flags_mex.png"
                    alt="mex-flag"
                    width="50"
                    height="30"
                  />
                </v-col>
              </v-row>
              <v-btn
                small
                color="white"
                class="btn-custom mt-2"
                elevation="0"
                @click="dialogAdjusts = true"
              >
                <v-icon left dark color="#F0BE43"
                  >mdi-plus-circle-outline</v-icon
                >
                <span class="buttonAddFounds">Añadir fondos</span>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <add-founds-dialog
      :dialog="dialogAddFounds"
      :data="dataAddFounds"
      @close="closeModalAddFounds"
    />

    <!-- Modal para historial de USD -->

    <v-dialog v-model="dialogAdjusts" persistent max-width="700px" class="pa-8">
      <v-card>
        <v-card-title class="d-flex justify-space-between align-center">
          <span class="title font-weight-bold text-textblack">
            Ajustes disponibles
          </span>
          <v-btn icon @click="dialogAdjusts = false">
            <v-icon color="#2E404E">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-2">
          <v-sheet rounded="lg" class="pa-13">
            <!-- <div class="mb-4">
              <div class="subtitle-1 font-weight-bold text-textblack">
                Detalle
              </div>
              <v-divider></v-divider>
            </div> -->

            <div class="my-2">
              <v-simple-table class="table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">Supplier</th>
                      <th class="text-center">Inovice</th>
                      <th class="text-center">Monto MXN</th>
                      <th class="text-center">Tipo de cambio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in exchangeRateAdjustments" :key="item.id">
                      <td class="text-center">
                        {{ item.exchangeRate.contactWallet.sName }}
                      </td>
                      <td class="text-center">
                        {{ item.exchangeRate.sInvoiceNumber }}
                      </td>
                      <td class="text-center">
                        {{ formatMoney(item.dAmount) }}
                      </td>
                      <td class="text-center">
                        {{ formatMoney(item.sCurrency) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddFoundsDialog from "./addFounds.vue";

export default {
  components: {
    AddFoundsDialog
  },
  props: {
    texts: {
      type: Object,
      default: () => ({})
    },
    actualCurrency: {
      type: Number | String,
      default: "0"
    },
    balanceMxn: {
      type: String,
      default: "0"
    },
    balanceUsd: {
      type: String,
      default: "0"
    },
    balance: {
      type: String,
      default: "0"
    },
    formatMoney: {
      type: Function,
      default: () => {}
    },
    openModalAddFounds: {
      type: Function,
      default: () => {}
    },
    dialogAddFounds: {
      type: Boolean,
      default: false
    },
    closeModalAddFounds: {
      type: Function,
      default: () => {}
    },
    dataAddFounds: {
      type: Object,
      default: () => ({})
    },
    getActualCurrency: {
      type: Function,
      default: () => {}
    },
    actualCurrencyMxn: {
      type: String,
      default: "0"
    },
    userName: {
      type: String,
      default: ""
    },
    exchangeRateAdjustments: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      timerCount: 120,
      dialog: false,
      date: new Date().toISOString().substr(0, 7),
      dialogAdjusts: false
    };
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      if (this.timerCount > 0) {
        setTimeout(() => {
          this.timerCount--;
          this.startTimer();
        }, 1000);
      } else {
        this.getActualCurrency();
        this.timerCount = 120;
        this.startTimer();
      }
    },
    confirmDate() {
      const [year, month] = this.date.split("-");
      const payload = {
        year,
        month
      };
      console.log("Payload para enviar:", payload);
      // Aquí puedes llamar a tu API con el payload
      this.dialog = false; // Cierra el modal después de confirmar la selección
    }
  }
};
</script>

<style>
.card-main {
  width: 40vh;
  height: 16vh;
  position: relative;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

.card-content {
  position: absolute;
  top: 19px;
  left: 16px;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #f0be43;
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #f0be43;
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #f0be43;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}
</style>
